import { render, staticRenderFns } from "./worker.vue?vue&type=template&id=20787e53&scoped=true&"
import script from "./worker.vue?vue&type=script&lang=js&"
export * from "./worker.vue?vue&type=script&lang=js&"
import style0 from "./worker.vue?vue&type=style&index=0&id=20787e53&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../yiweb/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20787e53",
  null
  
)

export default component.exports