<style lang="less">
  .resume-modal {
    .fm-modal-body {
      background-image: url('/static/images/resume.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: left center;
    }
  }
</style>

<style lang="less" scoped>
  .titile {
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    color: #000;
    margin: 50px 0 0 0;
    line-height: 19px;
  }
  .wrap {
    padding: 20px 58px 100px 58px;
  }
  .sub-title {
    font-size: 14px;
    color: rgba(0, 0, 0, .85);
    display: flex;
    align-items: center;
    font-weight: 800;
    &::after {
      content: '';
      display: inline-block;
      flex: 1;
      margin-left: 10px;
      background-color: #E8E8E8;
      height: 1px;
    }
  }
  .info {
    display: flex;
    align-items: center;
    position: relative;
    table {
      padding: 10px 10px 10px 0;
      flex: 1;
      td {
        line-height: 30px;
      }
    }
    .avatar {
      position: absolute;
      right: 8px;
      top: 0px;
      width: 85px;
      height: 96px;
      margin-left: 20px;
      background-size: contain;
      background-repeat: no-repeat;
      background-color: #FFF;
      border: 1px dashed #EEE;
      background-position: center;
    }
    .avatar-d {
      width: 85px;
      height: 85px;
    }
  }
  .edu-his {
    margin-top: 10px;
    width: 100%;
    border-spacing: 1px;
    border-collapse: collapse;
    border: 1px solid #E8E8E8;
    background-color: #FFF;
    tr:first-of-type, td:first-of-type {
      background: #FAFAFA;
    }
    td {
      color: rgba(0, 0, 0, 0.45);
      border: 1px solid #E8E8E8;
      padding: 5px 8px;
    }
  }
  .work-info {
    td {
      line-height: 30px;
      margin-right: 20px;
    }
  }
</style>

<template>
  <fm-modal :mask-closable="true" v-bind="$attrs" class="resume-modal" width="887px" @cancel="$emit('cancel')">
    <div class="titile">个人简历</div>
    <div class="wrap">
      <div class="sub-title">个人信息</div>
      <div class="info">
        <table>
          <tr>
            <td>姓名：<span>{{data.name || '——'}}</span></td>
            <td>出生日期：<span>{{data.birth ? data.birth.slice(0, 10) : '——'}}</span></td>
            <td>政治面貌：<span>{{data.politicalStatus || '——'}}</span></td>
          </tr>
          <tr>
            <td>性别：<span>{{data.sex || '——'}}</span></td>
            <td>电话号码：<span>——</span></td>
            <td>籍贯：<span>{{data.hometown || '——'}}</span></td>
          </tr>
          <tr>
            <td>民族：<span>{{data.clan || '——'}}</span></td>
            <td>学历：<span>{{data.education || '——'}}</span></td>
            <td>身份证号码：<span>{{data.idNo || '——'}}</span></td>
          </tr>
          <tr>
            <td>工号：<span>{{data.code || '——'}}</span></td>
            <td>在岗状态：<span>{{data.jobTypeC || '——'}}</span></td>
            <td>是否在编：<span>{{data.type || '——'}}</span></td>
          </tr>
          <tr>
            <td>连续工龄：<span>{{data.maritalStatus || '——'}}</span></td>
            <td>间断工龄：<span>{{data.position || '——'}}</span></td>
            <td>是否港澳台及外籍人士：<span>{{data.graduatedSchool || '——'}}</span></td>
          </tr>
          <tr>
            <td>人员类别：<span>{{data.jobTypeC || '——'}}</span></td>
            <td>是否政工人员：<span>{{data.zg || '——'}}</span></td>
            <td>参加工作时间：<span>{{data.correctionDate ? data.correctionDate.slice(0, 10) : '——'}}</span></td>
          </tr>
          <tr>
            <td>是否特殊人才：<span>{{data.agreementCode || '——'}}</span></td>
            <td>入职方式：<span>{{data.employmentForm || '——'}}</span></td>
            <td>进入本单位时间：<span>{{data.entryDate ? data.entryDate.slice(0, 10) : '——'}}</span></td>
          </tr>
          <tr>
            <td>个人身份：<span>{{data.sf || '——'}}</span></td>
            <td>离职方式：<span>{{data.discipline || '——'}}</span></td>
            <td>是否专职：<span>{{data.zhuanzhi || '——'}}</span></td>
          </tr>
          <tr>
            <td>是否双肩挑：<span>{{data.shuangjiantiao}}</span></td>
            <td>所属岗位：<span>{{data && data.positionId ? positionMap[data.positionId] || '——' : '——'}}</span></td>
            <!-- <td>可执勤岗位：<span>{{data && data.positionIds && data.positionIds.length > 0 ? data.positionIds.map(v => positionMap[v]).filter(v => v).join(',') : '——'}}</span></td> -->
            <td>可执勤岗位：<span>——</span></td>
          </tr>
          
        </table>
        <img class="avatar" :class="{'avatar-d': !photoSrc}" :src="photoSrc || '/static/images/icon/merit/1.png'"/>
      </div>
      <div class="sub-title">教育背景</div>
      <table class="edu-his">
        <colgroup>
          <col width="220">
        </colgroup>
        <tr>
          <td>时间</td>
          <td>学校</td>
          <td>学历</td>
          <td>专业</td>
        </tr>
        <tr v-for="(item, index) in eduHisData" :key="index">
          <td><span style="width:200px;">{{(item.startDate ? item.startDate.slice(0, 10) : '无开始时间') + '至' + (item.endDate ? item.endDate.slice(0, 10) : '今')}}</span></td>
          <td>{{item.school || '-'}}</td>
          <td>{{item.edu || '-'}}</td>
          <td>{{item.pro || '-'}}</td>
        </tr>
      </table>
      <div class="sub-title" style="margin: 10px 0;">工作信息</div>
      <table class="work-info">
        <tr>
          <td>首次定岗类别：<span>{{data && data.fWjt && data.fWjt.jobTitleType ? data.fWjt.jobTitleType : '——'}}</span></td>
          <td>现任岗位类别：<span>{{data && data.lWjt && data.lWjt.jobTitleType ? data.lWjt.jobTitleType : '——'}}</span></td>
        </tr>
        <tr>
          <td>首次定岗级别：<span>{{data && data.fWjt && data.fWjt.type ? (data.fWjt.type === '医师' ? data.fWjt.yTitle :data.fWjt.hTitle ) : '——'}}</span></td>
          <td>现任岗位级别：<span>{{data && data.lWjt && data.lWjt.type ? (data.lWjt.type === '医师' ? data.lWjt.yTitle :data.lWjt.hTitle ) : '——'}}</span></td>
        </tr>
        <tr>
          <td>首次定岗等级：<span>{{data && data.fWjt && data.fWjt.jobTitleTitle ? data.fWjt.jobTitleTitle : '——'}}</span></td>
          <td>现任岗位等级：<span>{{data && data.lWjt && data.lWjt.jobTitleTitle ? data.lWjt.jobTitleTitle : '——'}}</span></td>
        </tr>
        <tr>
          <td>首次定岗在岗时间：<span>{{data && data.fWjt && data.fWjt.startDate ? data.fWjt.startDate.slice(0, 10) : '——'}}至{{data && data.fWjt && data.fWjt.endDate ? data.fWjt.endDate.slice(0, 10) : (data && data.fWjt && data.fWjt.startDate ? '今' : '——')}}</span></td>
          <td>现任岗位在岗时间：<span>{{data && data.lWjt && data.lWjt.startDate ? data.lWjt.startDate.slice(0, 10) : '——'}}至{{data && data.lWjt && data.lWjt.endDate ? data.lWjt.endDate.slice(0, 10) : (data && data.lWjt && data.lWjt.startDate ? '今' : '——')}}</span></td>
        </tr>
        <tr>
          <td>首次定岗在岗单位：<span>{{data && data.fWjt && data.fWjt.hisName ? data.fWjt.hisName : '——'}}</span></td>
          <td>现任岗位在岗单位：<span>{{data && data.lWjt && data.lWjt.hisName ? data.lWjt.hisName : '——'}}</span></td>
        </tr>
      </table>
    </div>
  </fm-modal>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    data: {
      type: Object, default () {
        return {}
      }
    },
    positionMap: {
      type: Object, default () {
        return {}
      }
    },
    eduHisData: {
      type: Array, default () {
        return []
      }
    },
    photoSrc: {
      type: String, default () {
        return null
      }
    }
  }
}
</script>
